import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import Box from "@mui/material/Box";
import ProjectHeader from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, featureData, tableData, keywords} from "../../data/products/encryption";
import {
  BackBox,
  Box4Title,
  ProductContact,
  SubTitleEnBox,
  SubTitleWhiteBox,
  TitleBox,
  TitleEnBox
} from "../../components/Common/Base";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";
import {autoPlay} from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import {styled, useTheme} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider/Divider";
import {purposeData} from "../../data/products/lng";
import MobileStepper from "@mui/material/MobileStepper";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    sx: {
      color: '#1BAFBF',
      borderBottom: 2,
      borderColor: 'divider'
    },
    label: (
      <Box>
        {
          featureData[index].title
        }
        <br />
        {
          featureData[index].titleEn
        }
      </Box>
    )
  };
}

const City3d = (props) => {
  const { location } = props;

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const [value, setValue] = React.useState(0);

  const maxSteps = 3;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(Number(index));
  };

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/encryption/01.mp4').default}
        backVideo={'products/encryption/01.m3u8'}
        backImg={require('../../images/products/encryption/encryption01.jpg').default}
        title={'提供全面的气象数据保护'}
        titleEn={'Data Encryption Solutions'}
        titleEn2={'Provides Comprehensive Weather Data Protection'}
        subTitle={'数据加密解决方案'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box2 */}
      <Box
        ref={eleShow}
        sx={{
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <TitleBox
          variant="h5"
          align="center"
        >
          数据可见不可得
        </TitleBox>
        <TitleEnBox
          gutterBottom
          variant="h6"
          align="center"
        >
          Data Visible and Unreachable
        </TitleEnBox>
        <Container
          maxWidth="xl"
          sx={{
            pt: 4,
          }}
        >
          <Grid
            sx={{
              textAlign: 'center',
            }}
            container
            spacing={4}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
            >
              <Box
                component="img"
                sx={{
                  width: '90%',
                }}
                src={require('../../images/products/encryption/03.png').default}
                alt='天气模拟变化--赑玄阁'
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  // fontWeight: '100',
                  textAlign: {
                    xs: 'center',
                  },
                }}
              >
                结构化数据表 (原始数据)
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                Structured Data Tables (Raw Data)
              </SubTitleEnBox>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  // fontWeight: '100',
                  textAlign: {
                    xs: 'center',
                  },
                }}
              >
                虚拟化数据产品
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                Virtualized Data Products
              </SubTitleEnBox>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  // fontWeight: '100',
                  textAlign: {
                    xs: 'center',
                  },
                }}
              >
                可视化用户终端
              </SubTitleWhiteBox>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
                sx={{
                  textAlign: {
                    xs: 'center',
                  }
                }}
              >
                Visualization User Terminal
              </SubTitleEnBox>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box3 */}
      <Box
        sx={{
          background: '#1BAFBF',
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            pt: 4,
            pb: 4,
          }}
        >
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
            }}
          >
            保障数据链路在服务器出发、开发、转达、使用的全方位安全闭环。
          </SubTitleWhiteBox>
          <SubTitleEnBox
            gutterBottom
            variant="h6"
            sx={{
              fontWeight: '100',
              textAlign: {
                xs: 'center',
              },
              color: '#fff',
            }}
          >
            Ensure a full range of secure closed-loop data link in server departure,
            development, transmission, and use.
          </SubTitleEnBox>
        </Container>
      </Box>
      {/* box4 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            产品功能
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Product Features
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={4}
          alignItems={'stretch'}
          sx={{
            mb: 1,
            display: {
              xs: 'none',
              md: 'flex',
            }
          }}
        >
          {
            featureData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={'pur-item-' + index}
                >
                  <FeatureItem
                    {...item}
                  />
                </Grid>
              )
            })
          }
        </Grid>
        <Box sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
          position: 'relative',
        }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {
              featureData.map((item, index) => (
                <div key={'img-' + index}>
                  {
                    Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        sx={{
                          height: '570px',
                          display: 'block',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                      >
                        <FeatureItem
                          {...item}
                        />
                      </Box>
                    ) : null
                  }
                </div>
              ))
            }
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <div />
            }
            backButton={
              <div />
            }
          />
        </Box>
      </Container>
      {/* box5 */}
      <Container
        maxWidth="xl"
        sx={{
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            }
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {
                  featureData.map((item, index) => {
                    return (
                      <TableCell key={'table-head-' + index}>
                        <TableTitle>
                          {item.title}
                          <br />
                          {item.titleEn}
                        </TableTitle>
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tableData.map((item, index) => {
                  return (
                    <TableRow key={'table-body-' + index}>
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            width: {
                              xs: '150px',
                              sm: '180px',
                              md: '240px',
                            }
                          }}
                        >
                          <TableIcon
                            sx={{
                              backgroundImage: `url(${item.imagePath})`
                            }}
                          />
                          <TableBodyTitle>
                            {item.title}
                            <br />
                            {item.titleEn}
                          </TableBodyTitle>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <TableBodyContent>
                          {item.title1}
                          <br />
                          {item.titleEn1}
                        </TableBodyContent>
                      </TableCell>
                      <TableCell>
                        <TableBodyContent>
                          {item.title2}
                          <br />
                          {item.titleEn2}
                        </TableBodyContent>
                      </TableCell>
                      <TableCell>
                        <TableBodyContent>
                          {item.title3}
                          <br />
                          {item.titleEn3}
                        </TableBodyContent>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            }
          }}
        >
          <Tabs
            value={Number(value)}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              {...a11yProps(0)}
            />
            <Tab
              {...a11yProps(1)}
            />
            <Tab
              {...a11yProps(2)}
            />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Table aria-label="simple table">
                <TableBody>
                  {
                    tableData.map((item, index) => {
                      return (
                        <TableRow key={'table-body-' + index}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                width: {
                                  xs: '150px',
                                  sm: '180px',
                                  md: '240px',
                                }
                              }}
                            >
                              <TableIcon
                                sx={{
                                  backgroundImage: `url(${item.imagePath})`
                                }}
                              />
                              <TableBodyTitle>
                                {item.title}
                                <br />
                                {item.titleEn}
                              </TableBodyTitle>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <TableBodyContent>
                              {item.title1}
                              <br />
                              {item.titleEn1}
                            </TableBodyContent>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Table aria-label="simple table">
                <TableBody>
                  {
                    tableData.map((item, index) => {
                      return (
                        <TableRow key={'table-body-' + index}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                width: {
                                  xs: '150px',
                                  sm: '180px',
                                  md: '240px',
                                }
                              }}
                            >
                              <TableIcon
                                sx={{
                                  backgroundImage: `url(${item.imagePath})`
                                }}
                              />
                              <TableBodyTitle>
                                {item.title}
                                <br />
                                {item.titleEn}
                              </TableBodyTitle>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <TableBodyContent>
                              {item.title2}
                              <br />
                              {item.titleEn2}
                            </TableBodyContent>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Table aria-label="simple table">
                <TableBody>
                  {
                    tableData.map((item, index) => {
                      return (
                        <TableRow key={'table-body-' + index}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                width: {
                                  xs: '150px',
                                  sm: '180px',
                                  md: '240px',
                                }
                              }}
                            >
                              <TableIcon
                                sx={{
                                  backgroundImage: `url(${item.imagePath})`
                                }}
                              />
                              <TableBodyTitle>
                                {item.title}
                                <br />
                                {item.titleEn}
                              </TableBodyTitle>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <TableBodyContent>
                              {item.title3}
                              <br />
                              {item.titleEn3}
                            </TableBodyContent>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
      {/* box6 */}
      <BackBox
        sx={{
          backgroundImage: `url(${require('../../images/products/encryption/15.jpg').default})`,
          minHeight: '36.8vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          mt: {
            xs: 4,
            md: 8,
            lg: 16,
          }
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            pt: {
              xs: 4,
              md: 8,
              lg: 16,
            },
            pb: {
              xs: 4,
              md: 8,
              lg: 16,
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '60%',
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                fontSize: {
                  sm: '2.5vw',
                  xs: '18px',
                  lg: '48px'
                },
                color: '#1BAFBF'
              }}
            >
              在混合云环境中发现并监控您最敏感的数据源。
              通过自动化简化合规性。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#1BAFBF'
              }}
            >
              Discover and monitor your most sensitive data sources across hybrid cloud environments. Simplify compliance through automation
            </SubTitleEnBox>
          </Box>
        </Container>
      </BackBox>
      {/* box7 */}
      <Container
        maxWidth="xl"
        sx={{
          p: 4,
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            科技成果鉴定
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Identification of scientific and technological achievements
          </Box4Title>
        </Stack>
        <Box>
          <SubTitleWhiteBox
            variant="h5"
            gutterBottom
            align="center"
            sx={{
            }}
          >
            已通过广东省科学技术情报研究所成果鉴定,
            鉴定结果为全国领先。
          </SubTitleWhiteBox>
          <SubTitleEnBox
            align="center"
            gutterBottom
            variant="h6"
            sx={{
              fontSize: {
                xs: '2vw',
                lg: '32px'
              },
            }}
          >
            It has passed the appraisal of Guangdong Science and Technology Information Institute, and the appraisal result is the leading one in China.
          </SubTitleEnBox>
        </Box>
      </Container>
      {/* box8 */}
      <ProductContact />
      {/* box9 */}
    </Layout>
  )
}


export default City3d;

const ImgCenter = styled(Box)(({theme}) => ({
  width: 120,
  height: 120,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  [theme.breakpoints.up('md')]: {
    width: 180,
    height: 180,
  },
  [theme.breakpoints.up('lg')]: {
    width: 240,
    height: 240,
  },
}));

const FeatureItem = (props) => {
  const { imagePath, title, titleEn, subTitle, subTitleEn, imagePath2 } = props;
  return (
    <Stack
      sx={{
        height: '100%',
        pb: 2,
        pt: 2,
        backgroundColor: '#071d26',
        borderRadius: '6px',
        ml: 'auto',
        mr: 'auto',
        maxWidth: '400px',
      }}
      alignItems="center"
    >
      <ImgCenter
        sx={{
          mb: {
            xs: 2,
            md: 4,
          },
          backgroundImage: `url(${imagePath})`
        }}
      />
      <Box
        sx={{
          height: {
            md: '100px',
            xs: '60px'
          },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontSize: {
              xs: '16px',
              sm: '18px',
              md: '20px'
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px'
            },
            whiteSpace: 'nowrap',
          }}
          align="center"
        >
          {titleEn}
        </Typography>
      </Box>
      <Stack
        sx={{
          p: {
            xs: 2,
            md: 4,
          },
          backgroundColor: '#0D3140',
          textAlign: 'center',
          width: '100%',
          height: {
            xs: '70px',
            md: '130px'
          },
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          sx={{
            color: '#04D94F'
          }}
        >
          {subTitle}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          variant="h6"
          sx={{
            color: '#1BAFBF'
          }}
        >
          {subTitleEn}
        </SubTitleEnBox>
      </Stack>
      <Box
        sx={{
          flex: 1,
          p: {
            xs: 2,
          },
        }}
      >
        <Box
          component="img"
          sx={{
            width: {
              xs: '50%',
              sm: '70%',
              md: '100%',
            },
          }}
          src={imagePath2}
          alt={title}
        />
      </Box>
    </Stack>
  )
}

const TableTitle = styled(Box)((...other) => {
  return {
    color: '#1BAFBF',
  }
})

const TableBodyTitle = styled(Box)(({theme}) => ({
  color: '#1BAFBF',
}))

const TableBodyContent = styled(Box)(({theme}) => ({
  wordBreak: 'break-all'
}))


const TableIcon = styled(Box)(({theme}) => ({
  verticalAlign: 'bottom',
  height: 30,
  width: 30,
  marginRight: 8,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.up('sm')]: {
    height: 40,
    width: 40,
    marginRight: 12,
  },
  [theme.breakpoints.up('md')]: {
    height: 50,
    width: 50,
    marginRight: 16,
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
