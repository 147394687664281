export const seoTitle = '数据加密解决方案 | 产品服务 | 赑玄阁';
export const seoDescription = '数据加密解决方案提供全面的气象数据保护，通过结构化数据表，提供给用户虚拟化的数据产品，使数据在用户端可见不可得。';
export const browserTitle = '数据加密解决方案 | 产品服务 | 赑玄阁';
export const keywords = '数据加密, 气象数据, 数据保护, 气象数据保护, 密钥管理';


export const featureData = [
  {
    imagePath: require('../../images/products/encryption/06.png').default,
    title: '数字服务站',
    titleEn: 'Data  Service Center',
    subTitle: '安全性高 使用便利',
    subTitleEn: 'High safety and ease of use',
    imagePath2: require('../../images/products/encryption/21.png').default,
  },
  {
    imagePath: require('../../images/products/encryption/07.png').default,
    title: '数字工作站',
    titleEn: 'Data Working Center',
    subTitle: '安全性极高 数据可见不可存',
    subTitleEn: 'Extremely secure, data is visible and cannot be stored',
    imagePath2: require('../../images/products/encryption/22.png').default,
  },
  {
    imagePath: require('../../images/products/encryption/08.png').default,
    title: '数据计算站',
    titleEn: 'Data maxComputer Center',
    subTitle: '内部完成计算 防止数据外流',
    subTitleEn: 'Computation done in-house to prevent data leakage',
    imagePath2: require('../../images/products/encryption/23.png').default,
  },
];


export const tableData = [
  {
    imagePath: require('../../images/products/encryption/11.png').default,
    title: '产品内容',
    titleEn: 'Product Content',
    title1: '天气预报、预警信号等',
    titleEn1: 'Weather forecast, Early warning signals, etc.',
    title2: '格点数据、观测数据',
    titleEn2: 'Grid point data, observation data',
    title3: '历史数据',
    titleEn3: 'Historical Data',
  },
  {
    imagePath: require('../../images/products/encryption/12.png').default,
    title: '管理监控手段',
    titleEn: 'Management monitoring tools',
    title1: '权限限制、HTTPS加密',
    titleEn1: 'Restricted permissions, HTTPS encryption',
    title2: '密钥管理、权限管理、人工智能行为分析',
    titleEn2: 'Key Management, permission management Artificial intelligence behavior analysis',
    title3: '数据不外流、远程数据仓库、用户操作留痕',
    titleEn3: 'No data outflow, remote data warehouse, user operation traceability',
  },
  {
    imagePath: require('../../images/products/encryption/13.png').default,
    title: '数据形式',
    titleEn: 'Data Form',
    title1: '提供半成品图片、网页、插件、接口等中间件产品',
    titleEn1: 'Provide middleware products such as semi-finished images, web pages, plug-ins, interfaces, etc.',
    title2: '提供数据成品编码器、安全接收机、安全浏览器第三方产品开发包',
    titleEn2: 'Provide third-party product development kits for finished data encoders, secure receivers, and secure browsers',
    title3: '提供数据成品编码器、安全接收机、安全浏览器第三方产品开发包',
    titleEn3: 'Provide data services Cloud computing, cloud hosting, data integration, and Big data computer services',
  },
  {
    imagePath: require('../../images/products/encryption/14.png').default,
    title: '服务对象',
    titleEn: 'Service Users',
    title1: '市、区各局委办为主',
    titleEn1: 'City and district bureaus and commissions',
    title2: '国企为主',
    titleEn2: 'State-owned enterprises',
    title3: '高校',
    titleEn3: 'University',
  },
];
